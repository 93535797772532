import { isNullish } from '@leyan/sand';
import { Ok } from '@leyan/result';

import { operator } from '../$';

export type ExistsExpression = {
  $exists: boolean;
};

export const $exists = /* @__PURE__ */ operator({
  name: '$exists',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(value, expression: ExistsExpression) {
    const { $exists } = expression;

    return Ok(isNullish(value) !== $exists);
  },
});
