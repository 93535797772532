import { now, wait } from '@leyan/sand';

/**
 * 限流器
 */
class RateLimiter {
  /**
   * 每秒产生令牌数
   */
  private _permitsPerSecond: number;

  /**
   * 最大存储令牌数
   */
  private _maxPermits: number;

  /**
   * 当前存储令牌数
   */
  private _storedPermits: number;

  /**
   * 下次可生成令牌时间点 (毫秒)
   */
  private _nextFreeTicketTime: number;

  /**
   * 单个令牌产生间隔时间 (毫秒)
   */
  private _stableIntervalTime: number;

  /**
   * 构造限流器
   *
   * @param permitsPerSecond 每秒产生令牌数 (正数)
   * @param maxPermits 最大存储令牌数，默认值等于每秒产生令牌数 (正数)
   */
  constructor(permitsPerSecond: number, maxPermits: number = permitsPerSecond) {
    this._permitsPerSecond = permitsPerSecond;
    this._maxPermits = maxPermits;
    this._storedPermits = 0;
    this._nextFreeTicketTime = now(true);
    this._stableIntervalTime = 1000 / permitsPerSecond;
  }

  /**
   * 刷新令牌数
   *
   * @param currentTime 当前时间点 (毫秒)
   * @returns
   */
  private _resync(currentTime: number) {
    if (currentTime > this._nextFreeTicketTime) {
      const newPermits = (currentTime - this._nextFreeTicketTime) / this._stableIntervalTime;
      this._storedPermits = Math.min(this._maxPermits, this._storedPermits + newPermits);
      this._nextFreeTicketTime = currentTime;
    }

    return this;
  }

  /**
   * 预留令牌供应获取
   *
   * @param currentTime 当前时间 (毫秒)
   * @param permits 获取令牌数 (正整数)
   * @returns 等待时间 (毫秒)
   */
  private _reserve(currentTime: number, permits: number) {
    this._resync(currentTime);

    // 操作系统校准时间可能导致计算预计等待时间小于 0
    const waitTime = Math.max(this._nextFreeTicketTime - currentTime, 0);
    const storedPermitsToSpend = Math.min(permits, this._storedPermits);
    const freshPermits = permits - storedPermitsToSpend;

    this._nextFreeTicketTime += freshPermits * this._stableIntervalTime;
    this._storedPermits -= storedPermitsToSpend;

    return waitTime;
  }

  /**
   * 判断在过期时间内是否可以获得令牌
   *
   * @param currentTime 当前时间点 (毫秒)
   * @param timeout 超时时间 (毫秒)
   * @returns 是否可以获取令牌
   */
  private _canAcquire(currentTime: number, timeout: number) {
    return this._nextFreeTicketTime - timeout <= currentTime;
  }

  /**
   * 获取每秒产生令牌数
   *
   * @returns 每秒产生令牌数
   */
  getPermitsPerSecond() {
    return this._permitsPerSecond;
  }

  /**
   * 设置每秒产生令牌数
   *
   * @param permitsPerSecond 每秒产生令牌数 (正数)
   * @returns
   */
  setPermitsPerSecond(permitsPerSecond: number) {
    this._resync(now(true));

    this._permitsPerSecond = permitsPerSecond;
    this._stableIntervalTime = 1000 / permitsPerSecond;

    return this;
  }

  /**
   * 获取最大存储令牌数
   *
   * @returns 最大存储令牌数
   */
  getMaxPermits() {
    return this._maxPermits;
  }

  /**
   * 设置最大存储令牌数
   *
   * @param maxPermits 最大存储令牌数 (正数)
   * @returns
   */
  setMaxPermits(maxPermits: number) {
    this._storedPermits *= maxPermits / this._maxPermits;
    this._maxPermits = maxPermits;

    return this;
  }

  /**
   * 获取当前存储令牌数
   *
   * @returns 当前存储令牌数
   */
  getStoredPermits() {
    this._resync(now(true));

    return this._storedPermits;
  }

  /**
   * 获取下次可生成令牌时间点 (毫秒)
   *
   * @returns 下次可生成令牌时间点 (毫秒)
   */
  getNextFreeTicketTime() {
    this._resync(now(true));

    return this._nextFreeTicketTime;
  }

  /**
   * 获取令牌
   *
   * @param permits 令牌数 (正整数)
   * @returns 实际等待时间 (毫秒)
   */
  async acquire(permits: number) {
    const waitTime = this._reserve(now(true), permits);

    const actualTime = await wait(waitTime);

    // 操作系统校准时间可能导致计算的实际等待时间小于预计等待时间
    return Math.max(actualTime, waitTime);
  }

  /**
   * 尝试在过期时间内获取令牌
   *
   * @param permits 令牌数 (正整数)
   * @param timeout 过期时间 (毫秒)，默认值为 `0` (毫秒)
   * @returns 实际等待时间 (毫秒)，当返回值为 `-1` 时表示在过期时间内获取令牌失败
   */
  async tryAcquire(permits: number, timeout: number = 0) {
    const currentTime = now(true);

    if (!this._canAcquire(currentTime, timeout)) {
      return -1;
    }

    const waitTime = this._reserve(currentTime, permits);

    const actualTime = await wait(waitTime);

    // 操作系统校准时间可能导致计算的实际等待时间小于预计等待时间
    return Math.max(actualTime, waitTime);
  }
}

export default RateLimiter;
