import { Ok } from '@leyan/result';

import { operator } from '../$';

export type ConstExpression = {
  $const?: boolean;
};

export const $const = /* @__PURE__ */ operator({
  name: '$const',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(_value, expression: ConstExpression) {
    const { $const = true } = expression;

    return Ok($const);
  },
});
