import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import type { Expression, RegExpMatchResult } from '../types';
import { $, operator } from '../$';

export type ExtractExpression = {
  $extract: [regexp: string, match: Expression<RegExpMatchResult>];
};

export const $extract = /* @__PURE__ */ operator({
  name: '$extract',
  parse(target) {
    if (isNullish(target)) {
      return Err('$extract received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$extract can only used for `string`');
  },
  test(value, expression: ExtractExpression, path) {
    const { $extract } = expression;

    const [regexp, matchExpression] = $extract;

    try {
      return $(value.match(new RegExp(regexp)), matchExpression, [...path, '$extract']);
    } catch (_error) {
      return Err('$extract received invalid regular expression');
    }
  },
});
