import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type NotCaseSensitiveRegularExpressionExpression = {
  $nregex: string;
};

export const $nregex = /* @__PURE__ */ operator({
  name: '$nregex',
  parse(target) {
    if (isNullish(target)) {
      return Err('$nregex received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$nregex can only used for `string`');
  },
  test(value, expression: NotCaseSensitiveRegularExpressionExpression) {
    const { $nregex } = expression;

    try {
      return Ok(!new RegExp($nregex).test(value));
    } catch (_error) {
      return Err('$nregex received invalid regular expression');
    }
  },
});
