import { isArray } from '@leyan/sand';
import { Ok, unwrap } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

export type OrExpression<TValue> = {
  $or: Expression<TValue> | Expression<TValue>[];
};

export const $or = /* @__PURE__ */ operator({
  name: '$or',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(value, expression: OrExpression<any>, path) {
    const { $or } = expression;

    let items;
    let rebuild;

    if (isArray($or)) {
      items = $or;
      rebuild = (item: unknown) => item as Expression<any>;
    } else {
      items = Object.entries($or);
      rebuild = (item: unknown) => {
        const [key, value] = item as [string, Expression<any>];

        return { [key]: value };
      };
    }

    if (items.length === 0) {
      return Ok(true);
    }

    for (const item of items) {
      const expression = rebuild(item);

      const result = $(value, expression, path);

      if (unwrap(result, true)) {
        return result;
      }
    }

    return Ok(false);
  },
});
