import { EMPTY_FUNCTION, now, run } from '@leyan/sand';
import { RateLimiter } from '@leyan/rate-limiter';

import type {
  GetLitoEvent,
  GetLitoEventName,
  GetLitoEventOptions,
  LitoCollector,
  LitoEventDefinition,
  LitoLabels,
  LitoLink,
} from './types';
import BufferQueue from './BufferQueue';
import { createDeviceid, createRawLabels, createSessionid } from './utils';

export interface LitoOptions<
  TDefinition extends LitoEventDefinition,
  TGlobalLabels extends LitoLabels,
> {
  collect: LitoCollector;
  appname: string;
  appkey: string;
  version: string;
  deviceid?: string;
  sessionid?: string;
  buffer?: number;
  batch?: number;
  qps?: number;
  labels?: Partial<TGlobalLabels>;
  link?: LitoLink<TDefinition, TGlobalLabels>;
  onDiscard?(
    events: GetLitoEvent<TDefinition, GetLitoEventName<TDefinition>, TGlobalLabels>[],
  ): void;
  onCollect?(
    events: GetLitoEvent<TDefinition, GetLitoEventName<TDefinition>, TGlobalLabels>[],
  ): void;
  onError?(
    reason: unknown,
    events: GetLitoEvent<TDefinition, GetLitoEventName<TDefinition>, TGlobalLabels>[],
  ): void;
}

class Lito<
  TDefinition extends LitoEventDefinition = LitoEventDefinition,
  TGlobalLabels extends LitoLabels = LitoLabels,
> {
  private _labels: Partial<TGlobalLabels> = {};

  private _ready: boolean = false;

  private _alive: boolean = true;

  private _flushing?: Promise<boolean>;

  private _queue: BufferQueue<
    GetLitoEvent<TDefinition, GetLitoEventName<TDefinition>, TGlobalLabels>
  > = new BufferQueue(1000, 50);

  private _rateLimiter?: RateLimiter;

  private _perform?<TName extends GetLitoEventName<TDefinition>>(
    event: GetLitoEvent<TDefinition, TName, TGlobalLabels>,
  ): void;

  private _collect?(): Promise<boolean>;

  private async _tryFlush(force?: boolean): Promise<boolean> {
    let result = false;

    if (!this._queue.isEmpty()) {
      if (!force) {
        await this._rateLimiter!.acquire(1);
      }

      if (!this._queue.isEmpty()) {
        result = await this._collect!();

        if (!this._queue.isEmpty()) {
          return this._tryFlush(force);
        }
      }
    }

    return result;
  }

  init(options: LitoOptions<TDefinition, TGlobalLabels>) {
    const {
      collect,
      appname,
      appkey,
      version,
      deviceid = createDeviceid(),
      sessionid = createSessionid(),
      buffer = 1000,
      batch = 50,
      qps = 1,
      labels = {},
      link,
      onDiscard = EMPTY_FUNCTION,
      onCollect = EMPTY_FUNCTION,
      onError = EMPTY_FUNCTION,
    } = options;

    const events = this._queue.empty();

    this._labels = { ...labels };

    this._perform = run(() => {
      const perform = <TName extends GetLitoEventName<TDefinition>>(
        event: GetLitoEvent<TDefinition, TName, TGlobalLabels>,
      ) => {
        if (this._alive) {
          const events = this._queue.enqueue(event);

          if (events.length > 0) {
            onDiscard(events);
          }
        } else {
          onDiscard([event]);
        }
      };

      if (link) {
        return <TName extends GetLitoEventName<TDefinition>>(
          event: GetLitoEvent<TDefinition, TName, TGlobalLabels>,
        ) => {
          return link(event, () => {
            return perform(event);
          });
        };
      }

      return perform;
    });

    this._collect = async () => {
      const events = this._queue.dequeue(batch);

      if (events.length > 0) {
        try {
          await collect({
            appname,
            appkey,
            version,
            deviceid,
            sessionid,
            events: events.map((event) => {
              return {
                ...event,
                labels: createRawLabels(event.labels),
              };
            }),
          });

          onCollect(events);

          return true;
        } catch (error) {
          onError(error, events);
        }
      }

      return false;
    };

    if (this._queue.buffer !== buffer || this._queue.overflow !== batch) {
      this._queue = new BufferQueue(buffer, batch);
    }

    if (!this._rateLimiter) {
      this._rateLimiter = new RateLimiter(qps);
    } else if (this._rateLimiter.getPermitsPerSecond() !== qps) {
      this._rateLimiter.setPermitsPerSecond(qps);
      this._rateLimiter.setMaxPermits(qps);
    }

    this._ready = true;
    this._alive = true;

    if (events.length > 0) {
      for (const event of events) {
        this.capture(event, true);
      }

      this.flush();
    }

    return this;
  }

  size() {
    return this._queue.size();
  }

  isReady() {
    return this._ready;
  }

  isAlive() {
    return this._alive;
  }

  isFlushing() {
    return Boolean(this._flushing);
  }

  async flush(force?: boolean) {
    if (!this._ready) {
      return false;
    }

    if (!force) {
      if (!this._flushing) {
        this._flushing = this._tryFlush().finally(() => {
          this._flushing = undefined;
        });
      }

      return this._flushing;
    }

    return this._tryFlush(true);
  }

  async shutdown() {
    this._alive = false;

    await this.flush(true);
  }

  getLabels() {
    return this._labels;
  }

  setLabels(labels: Partial<TGlobalLabels>): this;

  setLabels(update: (labels: Partial<TGlobalLabels>) => Partial<TGlobalLabels>): this;

  setLabels(maybeLabels: any) {
    const labels = typeof maybeLabels === 'function' ? maybeLabels(this._labels) : maybeLabels;

    this._labels = labels;

    return this;
  }

  capture<TName extends GetLitoEventName<TDefinition>>(
    options: GetLitoEventOptions<TDefinition, TName>,
    lazy?: boolean,
  ) {
    const { name, value = 1, labels, timestamp = now() } = options;

    const event = {
      name,
      value,
      labels: {
        ...this._labels,
        ...labels,
      },
      timestamp,
    } as GetLitoEvent<TDefinition, TName, TGlobalLabels>;

    if (this._ready) {
      run(async () => {
        if (lazy && this._flushing) {
          await this._flushing;
        }

        this._perform!(event);

        if (!lazy) {
          this.flush();
        }
      });
    } else {
      this._queue.enqueue(event);
    }

    return this;
  }
}

export default Lito;
