import { isNullish, isNumber } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type LessThanOrEqualToExpression = {
  $lte: number;
};

export const $lte = /* @__PURE__ */ operator({
  name: '$lte',
  parse(target) {
    if (isNullish(target)) {
      return Err('$lte received nullish value');
    }

    if (isNumber(target)) {
      return Ok(target);
    }

    return Err('$lte can only used for `number`');
  },
  test(value, expression: LessThanOrEqualToExpression) {
    const { $lte } = expression;

    return Ok(value <= $lte);
  },
});
