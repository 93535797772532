import { Configuration } from '@leyan/configuration';
import { now, shallowEquals } from '@leyan/sand';

import type { Feature, FeatureResult, FeatureResultSource } from './types';

/**
 * 特性配置
 */
class FeatureConfiguration<TValue> extends Configuration<TValue> {
  /**
   * 特性
   */
  readonly feature: Feature<{ id: string; value: TValue }>;

  /**
   * 更新时间
   */
  private _updateAt: number = now(true);

  /**
   * 来源
   */
  private _source: FeatureResultSource = 'localDefaultValue';

  /**
   * 缓存唯一标识
   */
  private _cacheKey?: string;

  /**
   * 创建特性配置
   *
   * @param feature 特性
   */
  constructor(feature: Feature<{ id: string; value: TValue }>) {
    super(feature.default, { equals: shallowEquals });

    this.feature = feature;
  }

  /**
   * 更新时间
   */
  get updateAt() {
    return this._updateAt;
  }

  /**
   * 来源
   */
  get source() {
    return this._source;
  }

  /**
   * 缓存唯一标识
   */
  get cacheKey() {
    return this._cacheKey;
  }

  /**
   * 更新配置
   *
   * @internal Growthbook 内部使用
   * @param result 特性结果
   */
  $update(result?: FeatureResult<TValue>) {
    if (result) {
      this._cacheKey = result.cacheKey;

      if (result.source !== 'unknownFeature') {
        this._source = result.source;

        this.set(result.value as TValue);
      } else if (this._source !== 'unknownFeature') {
        this._source = 'unknownFeature';

        this.set(this.feature.default);
      }
    }

    this._updateAt = now(true);

    return this;
  }
}

export default FeatureConfiguration;
