const INJECTOR_UNSET_VALUE = Symbol('INJECTION_UNSET_VALUE');

class Injector<T> {
  private _default: T;

  private _value: T;

  constructor(value: T = INJECTOR_UNSET_VALUE as T) {
    this._default = value;

    this._value = value;
  }

  dispose() {
    this._value = this._default;
  }

  provide(value: T) {
    this._value = value;
  }

  inject() {
    if (this._value === INJECTOR_UNSET_VALUE) {
      throw new Error('inject() may be used only after provide()');
    }

    return this._value;
  }
}

export default Injector;
