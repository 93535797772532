import debug from 'debug';

import type { Logger, LoggerContext, LoggerLabels, LogLevel } from './types';
import NoopLogger from './NoopLogger';
import { appendParams, combineMessages, formatLabels, formatName } from './utils';

class DebugLogger extends NoopLogger implements Logger {
  readonly namespace: string;

  private _context: LoggerContext;

  private _logger: Logger;

  constructor(namespace: string, context: LoggerContext = {}) {
    super(({ name = '', level = 'info', message = '', params = [], labels }) => {
      const context = Object.entries(this._context).reduce<LoggerLabels>((result, [key, value]) => {
        const text = String(typeof value === 'function' ? value() : value).trim();

        if (text) {
          // eslint-disable-next-line no-param-reassign
          result[key] = text;
        }

        return result;
      }, {});

      this._logger[level](
        combineMessages(formatName(name), message),
        ...appendParams(params, formatLabels({ ...context, ...labels })),
      );
    });

    this.namespace = namespace;
    this._context = context;
    this._logger = {
      silly: debug(`${namespace}:silly`),
      debug: debug(`${namespace}:debug`),
      verbose: debug(`${namespace}:verbose`),
      info: debug(`${namespace}:info`),
      warn: debug(`${namespace}:warn`),
      error: debug(`${namespace}:error`),
    };
  }

  disable() {
    debug.disable();

    return this;
  }

  enable(...logLevels: LogLevel[]) {
    if (logLevels.length === 0) {
      debug.enable(`${this.namespace}:*`);
    } else {
      debug.enable(logLevels.map((logLevel) => `${this.namespace}:${logLevel}`).join(','));
    }

    return this;
  }

  extend(namespace: string, delimiter = '/') {
    return new DebugLogger(
      this.namespace ? `${this.namespace}${delimiter}${namespace}` : namespace,
      this._context,
    );
  }

  getContext() {
    return this._context;
  }

  setContext(context: LoggerContext): this;

  setContext(updater: (context: LoggerContext) => LoggerContext): this;

  setContext(maybeContext: any) {
    const context = typeof maybeContext === 'function' ? maybeContext(this._context) : maybeContext;

    this._context = context;

    return this;
  }
}

export default DebugLogger;
