import { isArray, isNullish } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

export type TypeExpression<TValue> = {
  $type: Expression<
    TValue extends boolean
      ? 'boolean'
      : TValue extends number
        ? 'number'
        : TValue extends string
          ? 'string'
          : TValue extends readonly any[]
            ? 'array'
            : TValue extends object
              ? 'object'
              : never
  >;
};

export const $type = /* @__PURE__ */ operator({
  name: '$type',
  parse(target) {
    if (isNullish(target)) {
      return Err('$type received nullish value');
    }

    return Ok<unknown>(target);
  },
  test(value, expression: TypeExpression<any>, path) {
    const { $type } = expression;

    const type = isArray(value) ? 'array' : typeof value;

    if (
      type === 'array' ||
      type === 'object' ||
      type === 'string' ||
      type === 'number' ||
      type === 'boolean'
    ) {
      return $(type, $type, [...path, '$type']);
    }

    return Err('$type received unknown type');
  },
});
