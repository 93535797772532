import { run } from './function';

export const now = /* @__PURE__ */ run(() => {
  if (
    typeof performance === 'object' &&
    typeof performance.timeOrigin === 'number' &&
    typeof performance.now === 'function'
  ) {
    return (highPrecision: boolean = false) => {
      const value = performance.timeOrigin + performance.now();

      if (highPrecision) {
        return value;
      }

      return Math.floor(value);
    };
  }

  return () => {
    return Date.now();
  };
});
