import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type StartsWithExpression = {
  $startsWith: string;
};

export const $startsWith = /* @__PURE__ */ operator({
  name: '$startsWith',
  parse(target) {
    if (isNullish(target)) {
      return Err('$startsWith received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$startsWith can only used for `string`');
  },
  test(value, expression: StartsWithExpression) {
    const { $startsWith } = expression;

    return Ok(value.startsWith($startsWith));
  },
});
