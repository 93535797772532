import { EMPTY_OBJECT } from './lang';
import { run } from './function';
import { get } from './object';
import { matchAll } from './string';

export type TemplateToken =
  | {
      kind: 'text';
      text: string;
      index: number;
    }
  | {
      kind: 'variable';
      text: string;
      name: string;
      index: number;
    };

export interface TemplateParseResult {
  hasVariables: boolean;
  tokens: TemplateToken[];
}

const DEFAULT_TEMPLATE_VARIABLE_REG = /\{([^{}]+)\}/;

let TEMPLATE_CACHE: Map<RegExp, Map<string, TemplateParseResult>>;

export interface TemplateParseOptions {
  rule?: RegExp;
  cache?: boolean;
}

export function parse(template: string, options: TemplateParseOptions = EMPTY_OBJECT) {
  const { rule = DEFAULT_TEMPLATE_VARIABLE_REG, cache = true } = options;

  function doParse() {
    const hasVariables = rule.test(template);
    const tokens: TemplateToken[] = [];

    if (hasVariables) {
      const reg = new RegExp(rule.source, rule.global ? rule.flags : `${rule.flags}g`);

      let current = 0;

      for (const matched of matchAll(template, reg)) {
        const { 0: match, 1: value, index } = matched;

        if (current < index) {
          tokens.push({
            kind: 'text',
            text: template.slice(current, index),
            index: current,
          });
        }

        tokens.push({
          kind: 'variable',
          text: match,
          name: value.trim(),
          index,
        });

        current = index + match.length;
      }

      if (current < template.length) {
        tokens.push({
          kind: 'text',
          text: template.slice(current),
          index: current,
        });
      }
    } else {
      tokens.push({
        kind: 'text',
        text: template,
        index: 0,
      });
    }

    return {
      hasVariables,
      tokens,
    };
  }

  let result;

  if (cache) {
    if (!TEMPLATE_CACHE) {
      TEMPLATE_CACHE = new Map();
    }

    let ruleCache = TEMPLATE_CACHE.get(rule);

    if (!ruleCache) {
      ruleCache = new Map();

      TEMPLATE_CACHE.set(rule, ruleCache);
    }

    result = ruleCache.get(template);

    if (!result) {
      result = doParse();

      ruleCache.set(template, result);
    }
  } else {
    result = doParse();
  }

  return result;
}

export type TemplateVariables = {
  [key: string]: unknown;
};

export interface TemplateRenderOptions extends TemplateParseOptions {
  escape?: boolean;
}

export function render(
  template: string,
  variables: TemplateVariables = EMPTY_OBJECT,
  options: TemplateRenderOptions = EMPTY_OBJECT,
) {
  const { escape, cache, rule } = options;

  const { hasVariables, tokens } = parse(template, { rule, cache });

  if (hasVariables) {
    const stringify = run(() => {
      const stringify = (value: unknown) => {
        return String(value);
      };

      if (escape) {
        return (value: unknown) => {
          return encodeURIComponent(stringify(value));
        };
      }

      return stringify;
    });

    return tokens
      .map((token) => {
        if (token.kind === 'variable') {
          const value = token.name.includes('.')
            ? get(variables, token.name.split('.'))
            : variables[token.name];

          if (value !== undefined) {
            return stringify(value);
          }
        }

        return token.text;
      })
      .join('');
  }

  return template;
}
