import { isNullish, isNumber } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type LessThanExpression = {
  $lt: number;
};

export const $lt = /* @__PURE__ */ operator({
  name: '$lt',
  parse(target) {
    if (isNullish(target)) {
      return Err('$lt received nullish value');
    }

    if (isNumber(target)) {
      return Ok(target);
    }

    return Err('$lt can only used for `number`');
  },
  test(value, expression: LessThanExpression) {
    const { $lt } = expression;

    return Ok(value < $lt);
  },
});
