import { run } from './function';

export function isGlobal(target: any): false | typeof globalThis {
  if (target && target.Math === Math) {
    return target;
  }

  return false;
}

export const $GLOBAL = /* @__PURE__ */ run(() => {
  return (
    isGlobal(typeof globalThis === 'object' && globalThis) ||
    isGlobal(typeof window === 'object' && window) ||
    // eslint-disable-next-line no-restricted-globals
    isGlobal(typeof self === 'object' && self) ||
    isGlobal(typeof global === 'object' && global) ||
    isGlobal(
      (function getGlobal(this: any) {
        return this;
      })(),
    ) ||
    // eslint-disable-next-line no-new-func
    isGlobal(new Function('return this')()) ||
    ({} as typeof globalThis)
  );
});
