import { isBoolean, isNullish, isNumber, isString } from '@leyan/sand';
import { Err, Ok, unwrap } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

type BooleanCastExpression = {
  boolean: Expression<boolean>;
};

type NumberCastExpression<TValue extends number> = {
  number: Expression<TValue>;
};

type StringCastExpression<TValue extends string> = {
  string: Expression<TValue>;
};

type TimestampCastExpression = {
  timestamp: Expression<number>;
};

export type CastExpression<TValue> = {
  $cast: TValue extends boolean
    ? NumberCastExpression<0 | 1> | StringCastExpression<'false' | 'true'>
    : TValue extends number
      ? BooleanCastExpression | StringCastExpression<`${number}`>
      : TValue extends string
        ? BooleanCastExpression | TimestampCastExpression | NumberCastExpression<number>
        : never;
};

export const $cast = /* @__PURE__ */ operator({
  name: '$cast',
  parse(target) {
    if (isNullish(target)) {
      return Err('$cast received nullish value');
    }

    const type = typeof target;

    if (isBoolean(target, type) || isNumber(target, type) || isString(target, type)) {
      return Ok(target);
    }

    return Err('$cast can only used for `boolean` `number` or `string`');
  },
  test(value, expression: CastExpression<boolean | number | string>, path) {
    const { $cast } = expression;
    const type = typeof value;

    const items = Object.entries($cast);

    if (items.length === 0) {
      return Err('$cast received invalid expression');
    }

    for (const [target, expression] of items) {
      let result;

      switch (target) {
        case 'boolean': {
          if (isNumber(value, type) || isString(value, type)) {
            const nextValue = Boolean(value);

            result = $(nextValue, expression, [...path, '$cast', 'boolean']);

            break;
          }

          return Err('$cast cast to `boolean` can only used for `number` or `string`');
        }
        case 'number': {
          if (isBoolean(value, type)) {
            const nextValue = value ? 1 : 0;

            result = $(nextValue, expression, [...path, '$cast', 'number']);

            break;
          }

          if (isString(value, type)) {
            const nextValue = Number(value);

            if (Number.isFinite(nextValue)) {
              result = $(nextValue, expression, path);

              break;
            }

            return Err('$cast cast to `number` from `string` failed');
          }

          return Err('$cast cast to `number` can only used for `boolean` or `string`');
        }
        case 'string': {
          if (isBoolean(value, type) || isNumber(value, type)) {
            const nextValue = String(value);

            result = $(nextValue, expression, [...path, '$cast', 'string']);

            break;
          }

          return Err('$cast cast to `string` can only used for `boolean` or `number`');
        }
        case 'timestamp': {
          if (isNumber(value, type) || isString(value, type)) {
            const nextValue = new Date(value).valueOf();

            if (Number.isFinite(nextValue)) {
              result = $(nextValue, expression, [...path, '$cast', 'timestamp']);

              break;
            }

            return Err('$cast cast to `timestamp` from `number` or `string` failed');
          }

          return Err('$cast cast to `timestamp` can only used for `number` or `string`');
        }
        default: {
          return Err('$cast can only cast to `boolean` `number` `string` or `timestamp`');
        }
      }

      if (!unwrap(result, false)) {
        return result;
      }
    }

    return Ok(true);
  },
});
