import { Err, Ok, isErr, isOk, type Result } from './result';

/**
 * 展开一层结果
 *
 * @param result 结果
 */
export function flatten<TValue, TReason>(
  result: Result<Result<TValue, TReason>, TReason>,
): Result<TValue, TReason> {
  if (isOk(result)) {
    return result.value;
  }

  return result;
}

/**
 * 转换结果成功值
 *
 * @param result 结果
 * @param fn 成功值转换函数
 */
export function map<TValueA, TReason, TValueB>(
  result: Result<TValueA, TReason>,
  fn: (value: TValueA) => TValueB,
): Result<TValueB, TReason> {
  if (isOk(result)) {
    return Ok(fn(result.value));
  }

  return result;
}

/**
 * 转换结果失败原因
 *
 * @param result 结果
 * @param fn 失败原因转换函数
 */
export function mapErr<TValue, TReasonA, TReasonB>(
  result: Result<TValue, TReasonA>,
  fn: (reason: TReasonA) => TReasonB,
): Result<TValue, TReasonB> {
  if (isErr(result)) {
    return Err(fn(result.reason));
  }

  return result;
}

/**
 * 如果第一个结果是成功则返回第一个结果，否则返回第二个结果
 *
 * @param first 第一个结果
 * @param second 第二个结果
 */
export function or<TValue, TReason>(
  first: Result<TValue, TReason>,
  second: Result<TValue, TReason>,
): Result<TValue, TReason> {
  if (isOk(first)) {
    return first;
  }

  return second;
}

/**
 * 继续操作成功
 *
 * @param result 结果
 * @param then 下一步成功操作函数
 */
export function tryThen<TValueA, TReason, TValueB>(
  result: Result<TValueA, TReason>,
  then: (value: TValueA) => Result<TValueB, TReason>,
): Result<TValueB, TReason> {
  if (isOk(result)) {
    return then(result.value);
  }

  return result;
}

/**
 * 从错误恢复操作
 *
 * @param result 结果
 * @param recover 错误恢复操作函数
 */
export function tryRecover<TValue, TReasonA, TReasonB>(
  result: Result<TValue, TReasonA>,
  recover: (reason: TReasonA) => Result<TValue, TReasonB>,
): Result<TValue, TReasonB> {
  if (isErr(result)) {
    return recover(result.reason);
  }

  return result;
}

/**
 * 如果结果为成功则返回成功值，否则返回成功默认值
 *
 * @param result 结果
 * @param value 成功默认值
 */
export function unwrap<TValue, TReason>(result: Result<TValue, TReason>, value: TValue): TValue {
  if (isOk(result)) {
    return result.value;
  }

  return value;
}

/**
 * 如果结果为错误则返回错误原因，否则返回错误默认原因
 *
 * @param result 结果
 * @param reason 错误默认原因
 */
export function unwrapErr<TValue, TReason>(
  result: Result<TValue, TReason>,
  reason: TReason,
): TReason {
  if (isErr(result)) {
    return result.reason;
  }

  return reason;
}

/**
 * 延迟创建第二个结果版本的 `or`
 *
 * @param first 第一个结果
 * @param second 延迟创建第二个结果函数
 */
export function lazyOr<TValue, TReason>(
  first: Result<TValue, TReason>,
  second: () => Result<TValue, TReason>,
): Result<TValue, TReason> {
  if (isOk(first)) {
    return first;
  }

  return second();
}

/**
 * 延迟创建成功默认值版本的 `unwrap`
 *
 * @param result 结果
 * @param value 延迟创建成功默认值函数
 */
export function lazyUnwrap<TValue, TReason>(
  result: Result<TValue, TReason>,
  value: () => TValue,
): TValue {
  if (isOk(result)) {
    return result.value;
  }

  return value();
}

/**
 * 延迟创建失败默认原因版本的 `unwrapErr`
 *
 * @param result 结果
 * @param reason 延迟创建失败默认原因函数
 */
export function lazyUnwrapErr<TValue, TReason>(
  result: Result<TValue, TReason>,
  reason: () => TReason,
): TReason {
  if (isErr(result)) {
    return result.reason;
  }

  return reason();
}
