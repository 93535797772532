import { EMPTY_OBJECT, SyncContext, isObject, pipe } from '@leyan/sand';
import { Err, Ok, mapErr, tryThen, unwrap } from '@leyan/result';

import type {
  Expression,
  Operator,
  OperatorsDefinition,
  Path,
  TestError,
  TestResult,
} from './types';

export const OPERATORS_DEFINITION_SYNC_CONTEXT =
  /* @__PURE__ */ new SyncContext<OperatorsDefinition>();

export function $<TValue>(
  value: TValue,
  expression: Expression<TValue>,
  path: Path,
): TestResult<string> {
  const entries = Object.entries(expression);

  if (entries.length === 0) {
    return Ok(true);
  }

  const definition: OperatorsDefinition = OPERATORS_DEFINITION_SYNC_CONTEXT.get() || EMPTY_OBJECT;

  let result;

  for (const [property, config] of Object.entries(expression)) {
    const operator = definition[property];

    if (operator) {
      const expression = { [property]: config };

      result = pipe(operator.parse(value))
        .next(($) => tryThen($, (value) => operator.test(value, expression, path)))
        .next(($) =>
          mapErr($, (reason) => {
            if (typeof reason === 'string') {
              return {
                operator: operator.name,
                expression,
                path,
                value,
                message: reason,
              } as TestError<string>;
            }

            return reason;
          }),
        )
        .result();
    } else if (isObject(config)) {
      result = $((value as any)?.[property], config, [...path, property]);
    } else {
      result = Err<TestError<string>>({
        operator: '$',
        expression,
        path,
        value,
        message: '$ received invalid expression',
      });
    }

    if (!unwrap(result, false)) {
      return result;
    }
  }

  return Ok(true);
}

export function operator<TValue, TName extends string, TExpression extends Expression<any>>(
  operator: Operator<TValue, TName, TExpression>,
) {
  return operator;
}
