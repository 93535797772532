import { isArray, isNullish, isString } from '@leyan/sand';
import { Err, Ok, isErr } from '@leyan/result';

import type { Expression, ItemType } from '../types';
import { $, operator } from '../$';

export type CountExpression<TValue> = {
  $count: TValue extends readonly any[]
    ? Expression<number> | [count: Expression<number>, items?: Expression<ItemType<TValue>>]
    : TValue extends string
      ? Expression<number> | [count: Expression<number>, items?: Expression<string>]
      : never;
};

export const $count = /* @__PURE__ */ operator({
  name: '$count',
  parse(target) {
    if (isNullish(target)) {
      return Err('$count received nullish value');
    }

    if (isString(target) || isArray(target)) {
      return Ok(target);
    }

    return Err('$count can only used for `array`');
  },
  test(value, expression: CountExpression<any[]>, path) {
    const { $count } = expression;

    const [countExpression, itemsExpression] = isArray($count) ? $count : [$count];

    let count;

    if (itemsExpression) {
      count = 0;

      let index = 0;

      for (const item of value) {
        const result = $(item, itemsExpression, [...path, index]);

        if (isErr(result)) {
          return result;
        }

        if (result.value) {
          count += 1;
        }

        index += 1;
      }
    } else {
      count = value.length;
    }

    return $(count, countExpression, [...path, '$count']);
  },
});
