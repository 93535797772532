import { isBoolean, isNullish, isNumber, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type NotEqualExpression<TValue extends boolean | number | string> = {
  $neq: TValue;
};

export const $neq = /* @__PURE__ */ operator({
  name: '$neq',
  parse(target) {
    if (isNullish(target)) {
      return Err('$neq received nullish value');
    }

    const type = typeof target;

    if (isBoolean(target, type) || isNumber(target, type) || isString(target, type)) {
      return Ok(target);
    }

    return Err('$neq can only used for `boolean` `number` or `string`');
  },
  test(value, expression: NotEqualExpression<boolean | number | string>) {
    const { $neq } = expression;

    return Ok($neq !== value);
  },
});
