import { EMPTY_PROMISE, isArray, isFunction, run } from '@leyan/sand';
import { isErr } from '@leyan/result';

import type {
  Attributes,
  EvaluationConfig,
  EvaluationResult,
  Evaluator,
  Feature,
  GetFeatureValue,
} from './types';
import FeatureConfiguration from './FeatureConfiguration';

function getErrorMessage(message: string | string[]) {
  if (isArray(message)) {
    return message.join(';');
  }

  return message;
}

/**
 * 特性评估队列任务
 */
interface EvaluationQueueTask {
  /**
   * 特性配置集合
   */
  configurations?: Set<FeatureConfiguration<unknown>>;
  /**
   * 任务 `Promise`
   */
  promise?: Promise<boolean>;
}

/**
 * 特性评估队列
 */
interface EvaluationQueue {
  /**
   * 当前运行中任务
   */
  running: EvaluationQueueTask;
  /**
   * 当前等待中任务
   */
  waiting: EvaluationQueueTask;
}

/**
 * `Growthbook` 特性管理器参数
 */
export interface GrowthbookOptions {
  /**
   * 应用名称
   */
  appName: string;
  /**
   * 应用密钥
   */
  appKey: string;
  /**
   * 应用版本
   */
  appVersion: string;
  /**
   * 设备 ID
   */
  deviceId: string;
  /**
   * 特性评估函数
   */
  evaluate: Evaluator;
  /**
   * 特性评估事件处理函数
   *
   * @param config 特性评估配置
   * @param result 特性评估结果
   */
  onEvaluation?(config: EvaluationConfig, result: EvaluationResult | undefined): void;
  /**
   * 错误事件处理函数
   *
   * @param reason 原因
   */
  onError?(reason: unknown): void;
  /**
   * 是否校验特性值
   */
  validate?: boolean;
  /**
   * 特性评估变化检测轮询时间 (毫秒)，默认 `300000`
   */
  interval?: number;
}

/**
 * `Growthbook` 特性评估管理器
 */
class Growthbook<TAttributes extends Attributes> {
  /**
   * 是否已启动
   */
  private _alive: boolean = false;

  /**
   * 特性评估队列
   */
  private _queue: EvaluationQueue = {
    running: {},
    waiting: {},
  };

  /**
   * 特性配置映射
   */
  private _configurations: Map<string, FeatureConfiguration<any>> = new Map();

  /**
   * 属性
   */
  private _attributes?: TAttributes;

  /**
   * 执行特性评估
   *
   * @param configurations 特性配置列表
   */
  private _evaluate?(configurations: FeatureConfiguration<any>[]): Promise<boolean>;

  /**
   * 执行清理
   */
  private _dispose?(): void;

  /**
   * 调度特性评估
   *
   * @param configurations 特性配置列表
   */
  private async _schedule(configurations: FeatureConfiguration<any>[]) {
    if (configurations.length === 0) {
      return false;
    }

    const {
      _queue: { running, waiting },
    } = this;

    const run = async () => {
      if (waiting.configurations) {
        running.configurations = waiting.configurations;
        waiting.configurations = undefined;

        try {
          return await this._evaluate!(Array.from(running.configurations));
        } finally {
          running.configurations = undefined;
          running.promise = waiting.promise;
          waiting.promise = undefined;
        }
      }

      return false;
    };

    if (!waiting.configurations) {
      waiting.configurations = new Set();
    }

    for (const configuration of configurations) {
      waiting.configurations.add(configuration);
    }

    if (running.configurations) {
      if (!waiting.promise) {
        waiting.promise = running.promise!.then(run);
      }

      return waiting.promise;
    }

    if (!running.promise) {
      running.promise = EMPTY_PROMISE.then(run);
    }

    return running.promise;
  }

  /**
   * 特性配置列表
   */
  get configurations() {
    return Array.from(this._configurations.values());
  }

  /**
   * 是否已启动
   */
  isAlive() {
    return this._alive;
  }

  /**
   * 获取属性
   */
  getAttributes() {
    return this._attributes;
  }

  /**
   * 设置属性
   *
   * @param update 更新函数
   */
  setAttributes(update: (attributes?: TAttributes) => TAttributes): this;

  /**
   * 设置属性
   *
   * @param attributes 属性
   */
  setAttributes(attributes: TAttributes): this;

  setAttributes(maybeAttributes: ((attributes?: TAttributes) => TAttributes) | TAttributes) {
    this._attributes = isFunction(maybeAttributes)
      ? maybeAttributes(this._attributes)
      : maybeAttributes;

    if (this._alive) {
      this.refresh();
    }

    return this;
  }

  /**
   * 初始化
   *
   * @param options 初始化参数
   */
  init(options: GrowthbookOptions) {
    const {
      appName,
      appKey,
      appVersion,
      deviceId,
      evaluate,
      onEvaluation,
      onError,
      validate,
      interval = 300000,
    } = options;

    this._alive = true;

    this._evaluate = async (configurations) => {
      try {
        const config: EvaluationConfig = {
          appName,
          appKey,
          appVersion,
          deviceId,
          attributes: { ...this._attributes },
          features: configurations.map((configuration) => {
            const {
              feature: { id },
              cacheKey,
            } = configuration;

            return {
              id,
              cacheKey,
            };
          }),
        };

        const evaluationResult = await evaluate(config);

        onEvaluation?.(config, evaluationResult);

        let result = true;

        if (evaluationResult) {
          for (const configuration of configurations) {
            const {
              feature: { id, schema },
            } = configuration;

            let featureResult = evaluationResult[id];

            // eslint-disable-next-line no-loop-func
            run(() => {
              if (
                featureResult &&
                featureResult.source !== 'unknownFeature' &&
                validate &&
                schema
              ) {
                const validationResult = schema(featureResult.value);

                if (isErr(validationResult)) {
                  onError?.(
                    new Error(
                      `Feature "${id}" validate error: ${getErrorMessage(validationResult.reason)}`,
                    ),
                  );

                  result = false;

                  return;
                }

                featureResult = {
                  ...featureResult,
                  value: validationResult.value,
                };
              }

              try {
                configuration.$update(featureResult);
              } catch (error) {
                onError?.(error);

                result = false;
              }
            });
          }
        }

        return result;
      } catch (error) {
        onError?.(error);

        return false;
      }
    };

    const loop = async () => {
      await this.refresh();

      const id = setTimeout(loop, interval);

      this._dispose = () => {
        this._dispose = undefined;

        clearTimeout(id);
      };
    };

    this._dispose?.();

    loop();

    return this;
  }

  /**
   * 关闭
   */
  shutdown() {
    if (this._alive) {
      this._dispose?.();

      this._alive = false;
    }

    return this;
  }

  /**
   * 刷新特性值
   *
   * @param feature 特性
   */
  async refresh(feature: Feature<any>): Promise<boolean>;

  /**
   * 刷新所有特性值
   */
  async refresh(): Promise<boolean>;

  async refresh(feature?: Feature<any>) {
    if (this._alive) {
      const configurations = feature
        ? [this.getConfiguration(feature)]
        : Array.from(this._configurations.values());

      return this._schedule(configurations);
    }

    return false;
  }

  /**
   * 预加载特性
   *
   * @param feature 特性
   */
  async preload(feature: Feature<any>): Promise<boolean>;

  /**
   * 预加载特性列表
   *
   * @param features 特性列表
   */
  async preload(features: Feature<any>[]): Promise<boolean>;

  async preload(feature: Feature<any> | Feature<any>[]) {
    const features = isArray(feature) ? feature : [feature];

    const configurations = features.map((feature) => {
      return this.getConfiguration(feature);
    });

    if (this._alive) {
      return this._schedule(configurations);
    }

    return false;
  }

  /**
   * 获取特性配置
   *
   * @param feature 特性
   */
  getConfiguration<TFeature extends Feature<any>>(
    feature: TFeature,
  ): FeatureConfiguration<GetFeatureValue<TFeature>> {
    const { id } = feature;

    let configuration = this._configurations.get(id);

    if (!configuration) {
      configuration = new FeatureConfiguration(feature);

      this._configurations.set(id, configuration);

      if (this._alive) {
        this.refresh(feature);
      }
    }

    return configuration;
  }
}

export default Growthbook;
