import { isBoolean, isNullish, isNumber, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type EqualExpression<TValue extends boolean | number | string> = {
  $eq: TValue;
};

export const $eq = /* @__PURE__ */ operator({
  name: '$eq',
  parse(target) {
    if (isNullish(target)) {
      return Err('$eq received nullish value');
    }

    const type = typeof target;

    if (isBoolean(target, type) || isNumber(target, type) || isString(target, type)) {
      return Ok(target);
    }

    return Err('$eq can only used for `boolean` `number` or `string`');
  },
  test(value, expression: EqualExpression<boolean | number | string>) {
    const { $eq } = expression;

    return Ok($eq === value);
  },
});
