import { isNullish, isNumber } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type GreaterThanOrEqualToExpression = {
  $gte: number;
};

export const $gte = /* @__PURE__ */ operator({
  name: '$gte',
  parse(target) {
    if (isNullish(target)) {
      return Err('$gte received nullish value');
    }

    if (isNumber(target)) {
      return Ok(target);
    }

    return Err('$gte can only used for `number`');
  },
  test(value, expression: GreaterThanOrEqualToExpression) {
    const { $gte } = expression;

    return Ok(value >= $gte);
  },
});
