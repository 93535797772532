import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type CaseInsensitiveRegularExpressionExpression = {
  $iregex: string;
};

export const $iregex = /* @__PURE__ */ operator({
  name: '$iregex',
  parse(target) {
    if (isNullish(target)) {
      return Err('$iregex received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$iregex can only used for `string`');
  },
  test(value, expression: CaseInsensitiveRegularExpressionExpression) {
    const { $iregex } = expression;

    try {
      return Ok(new RegExp($iregex, 'i').test(value));
    } catch (_error) {
      return Err('$iregex received invalid regular expression');
    }
  },
});
