import { disposable } from '@leyan/sand';

import type { Logger, LoggerHandle, LoggerMeta } from './types';
import ProxyLogger from './ProxyLogger';

class LoggerManager {
  private _store: Map<string, ProxyLogger> = new Map();

  private _handlers: Set<LoggerHandle> = new Set();

  private _logger: ProxyLogger;

  constructor(logger?: Logger) {
    this._logger = new ProxyLogger({ manager: this, name: '', logger });
  }

  $logging(meta: LoggerMeta) {
    for (const handle of this._handlers) {
      handle(meta);
    }

    return this;
  }

  onLogging(handle: LoggerHandle) {
    this._handlers.add(handle);

    return disposable(() => {
      this._handlers.delete(handle);
    });
  }

  hasLogger(): boolean;

  hasLogger(name: string): boolean;

  hasLogger(maybeName?: any) {
    if (typeof maybeName === 'string') {
      const name = maybeName;

      return this._store.has(name);
    }

    return this._logger !== undefined;
  }

  getLogger(): ProxyLogger;

  getLogger(name: string): ProxyLogger;

  getLogger(maybeName?: any) {
    if (typeof maybeName === 'string') {
      const name = maybeName;

      let proxyLogger = this._store.get(name);

      if (!proxyLogger) {
        proxyLogger = new ProxyLogger({
          name,
          manager: this,
        });

        this._store.set(name, proxyLogger);
      }

      return proxyLogger;
    }

    return this._logger;
  }

  setLogger(logger?: Logger): this;

  setLogger(name: string, logger?: Logger): this;

  setLogger(maybeName?: any, maybeLogger?: any) {
    if (typeof maybeName === 'string') {
      const name = maybeName;
      const logger = maybeLogger;

      const proxyLogger = this.getLogger(name);

      proxyLogger.setLogger(logger);

      return this;
    }

    const logger = maybeName;

    this._logger.setLogger(logger);

    return this;
  }

  *values() {
    for (const [name, logger] of this._store.entries()) {
      yield { name, logger };
    }
  }
}

export default LoggerManager;
