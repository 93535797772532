import { isBoolean, isNullish, isNumber, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type NotInExpression<TValue extends boolean | number | string> = {
  $nin: TValue[];
};

export const $nin = /* @__PURE__ */ operator({
  name: '$nin',
  parse(target) {
    if (isNullish(target)) {
      return Err('$nin received nullish value');
    }

    const type = typeof target;

    if (isBoolean(target, type) || isNumber(target, type) || isString(target, type)) {
      return Ok(target);
    }

    return Err('$in can only used for `boolean` `number` or `string`');
  },
  test(value, expression: NotInExpression<boolean | number | string>) {
    const { $nin } = expression;

    return Ok(!$nin.includes(value));
  },
});
