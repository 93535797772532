import { EMPTY_ARRAY } from '@leyan/sand';

import type { Expression, OperatorsDefinition, Path, TestResult } from './types';
import { $, OPERATORS_DEFINITION_SYNC_CONTEXT } from './$';
import {
  $and,
  $cast,
  $coalesce,
  $const,
  $contains,
  $count,
  $empty,
  $endsWith,
  $eq,
  $every,
  $exists,
  $extract,
  $extractAll,
  $gt,
  $gte,
  $in,
  $iregex,
  $lt,
  $lte,
  $neq,
  $nin,
  $niregex,
  $not,
  $nregex,
  $or,
  $regex,
  $some,
  $startsWith,
  $type,
} from './operators';

const PRESET_OPERATORS_DEFINITION = {
  $and,
  $cast,
  $coalesce,
  $const,
  $contains,
  $count,
  $empty,
  $endsWith,
  $eq,
  $every,
  $exists,
  $extract,
  $extractAll,
  $gt,
  $gte,
  $in,
  $iregex,
  $lt,
  $lte,
  $neq,
  $nin,
  $niregex,
  $not,
  $nregex,
  $or,
  $regex,
  $some,
  $startsWith,
  $type,
} as const satisfies OperatorsDefinition;

export function create<TDefinition extends OperatorsDefinition>(definition: TDefinition) {
  function tql<TValue>(
    value: TValue,
    expression: Expression<TValue>,
  ): TestResult<'$' | (keyof TDefinition & string)> {
    return OPERATORS_DEFINITION_SYNC_CONTEXT.run(definition, () => {
      return $(value, expression, EMPTY_ARRAY as Path) as TestResult<
        '$' | (keyof TDefinition & string)
      >;
    });
  }

  return tql;
}

export const tql = /* @__PURE__ */ create(PRESET_OPERATORS_DEFINITION);
