import type { Path } from './types';
import { hasOwnProperty } from './lang';

export function get<TValue>(target: any, path: Path): TValue {
  let current = target;

  for (const key of path) {
    if (!hasOwnProperty(current, key)) {
      return undefined as TValue;
    }

    current = current[key];
  }

  return current;
}
