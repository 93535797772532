import type { ADTType } from '@leyan/adt';

/**
 * 成功
 */
export type Ok<TValue> = ADTType<'Ok', { value: TValue }>;

/**
 * 错误
 */
export type Err<TReason> = ADTType<'Err', { reason: TReason }>;

/**
 * 结果
 */
export type Result<TValue, TReason> = Ok<TValue> | Err<TReason>;

/**
 * 创建成功
 *
 * @param value 成功值
 */
export function Ok<TValue>(value: TValue): Ok<TValue> {
  return {
    $TAG: 'Ok',
    value,
  };
}

/**
 * 创建错误
 *
 * @param reason 错误原因
 */
export function Err<TReason>(reason: TReason): Err<TReason> {
  return {
    $TAG: 'Err',
    reason,
  };
}

/**
 * 判断目标对象是否是成功
 *
 * @param target 目标对象
 */
export function isOk(target: any): target is Ok<any> {
  const candidate = target as Ok<any> | null | undefined;

  return candidate?.$TAG === 'Ok';
}

/**
 * 判断目标对象是否是错误
 *
 * @param target 目标对象
 */
export function isErr(target: any): target is Err<any> {
  const candidate = target as Err<any> | null | undefined;

  return candidate?.$TAG === 'Err';
}
