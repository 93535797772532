import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type ContainsExpression = {
  $contains: string;
};

export const $contains = /* @__PURE__ */ operator({
  name: '$contains',
  parse(target) {
    if (isNullish(target)) {
      return Err('$contains received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$contains can only used for `string`');
  },
  test(value, expression: ContainsExpression) {
    const { $contains } = expression;

    return Ok(value.includes($contains));
  },
});
