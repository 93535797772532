import { isArray } from '@leyan/sand';
import { Ok, unwrap } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

export type AndExpression<TValue> = {
  $and: Expression<TValue> | Expression<TValue>[];
};

export const $and = /* @__PURE__ */ operator({
  name: '$and',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(value, expression: AndExpression<any>, path) {
    const { $and } = expression;

    let items;
    let rebuild;

    if (isArray($and)) {
      items = $and;
      rebuild = (item: unknown) => item as Expression<any>;
    } else {
      items = Object.entries($and);
      rebuild = (item: unknown): Expression<any> => {
        const [key, value] = item as [string, Expression<any>];

        return { [key]: value };
      };
    }

    if (items.length === 0) {
      return Ok(true);
    }

    for (const item of items) {
      const expression = rebuild(item);

      const result = $(value, expression, path);

      if (!unwrap(result, false)) {
        return result;
      }
    }

    return Ok(true);
  },
});
