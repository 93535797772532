import { $GLOBAL } from '@leyan/sand';

import LoggerManager from './LoggerManager';

export const GLOBAL_LOGGER_MANAGER_SYMBOL = Symbol.for('__GLOBAL_LOGGER_MANAGER__');

let GLOBAL_LOGGER_MANAGER: LoggerManager = ($GLOBAL as any)[GLOBAL_LOGGER_MANAGER_SYMBOL];

if (!GLOBAL_LOGGER_MANAGER) {
  GLOBAL_LOGGER_MANAGER = new LoggerManager();

  if (typeof Object.defineProperty === 'function') {
    Object.defineProperty($GLOBAL, GLOBAL_LOGGER_MANAGER_SYMBOL, {
      get() {
        return GLOBAL_LOGGER_MANAGER;
      },
    });
  } else {
    ($GLOBAL as any)[GLOBAL_LOGGER_MANAGER_SYMBOL] = GLOBAL_LOGGER_MANAGER;
  }
}

export const loggerManager = GLOBAL_LOGGER_MANAGER;
