import { isArray, isNullish } from '@leyan/sand';
import { Err, Ok, unwrap } from '@leyan/result';

import type { Expression, ItemType } from '../types';
import { $, operator } from '../$';

export type EveryExpression<TValue extends readonly any[]> = {
  $every: Expression<ItemType<TValue>>;
};

export const $every = /* @__PURE__ */ operator({
  name: '$every',
  parse(target) {
    if (isNullish(target)) {
      return Err('$every received nullish value');
    }

    if (isArray(target)) {
      return Ok(target);
    }

    return Err('$every can only used for `array`');
  },
  test(value, expression: EveryExpression<any[]>, path) {
    const { $every } = expression;

    let index = 0;

    for (const item of value) {
      const result = $(item, $every, [...path, index]);

      if (!unwrap(result, false)) {
        return result;
      }

      index += 1;
    }

    return Ok(true);
  },
});
