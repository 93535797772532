import { isNullish, isString, matchAll } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import type { Expression, RegExpMatchResult } from '../types';
import { $, operator } from '../$';

export type ExtractAllExpression = {
  $extractAll: [regexp: string, matches: Expression<RegExpMatchResult[]>];
};

export const $extractAll = /* @__PURE__ */ operator({
  name: '$extractAll',
  parse(target) {
    if (isNullish(target)) {
      return Err('$extractAll received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$extractAll can only used for `string`');
  },
  test(value, expression: ExtractAllExpression, path) {
    const { $extractAll } = expression;

    const [regexp, matchesExpression] = $extractAll;

    try {
      return $([...matchAll(value, new RegExp(regexp, 'g'))], matchesExpression, [
        ...path,
        '$extractAll',
      ]);
    } catch (_error) {
      return Err('$extractAll received invalid regular expression');
    }
  },
});
