import type { LoggerLabels } from './types';

export function combineMessages(...messages: any[]): string {
  return messages
    .reduce<string[]>((result, param) => {
      switch (typeof param) {
        case 'number':
        case 'bigint': {
          const message = String(param);

          result.push(message);

          break;
        }
        default: {
          if (param) {
            const message = Array.isArray(param) ? combineMessages(...param) : String(param).trim();

            if (message.length > 0) {
              result.push(message);
            }
          }

          break;
        }
      }

      return result;
    }, [])
    .join(' ');
}

export function appendParams(params: any[], param: any) {
  return param ? [...params, param] : params;
}

export function formatName(name: string) {
  return name && `[${name}]`;
}

export function formatLabels(labels: LoggerLabels) {
  const text = Object.entries(labels)
    .reduce<string[]>((result, [key, value]) => {
      result.push(`${key}:${JSON.stringify(String(value).trim())}`);

      return result;
    }, [])
    .join(',');

  return text && `{${text}}`;
}
