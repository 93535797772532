import { EMPTY_FUNCTION } from './lang';
import { run } from './function';

export const reportUnhandledError = /* @__PURE__ */ run(() => {
  if (typeof setTimeout === 'function') {
    return (error: unknown) => {
      setTimeout(() => {
        throw error;
      });
    };
  }

  return EMPTY_FUNCTION;
});
