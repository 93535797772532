import { isArray, isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type EmptyExpression = {
  $empty: boolean;
};

export const $empty = /* @__PURE__ */ operator({
  name: '$empty',
  parse(target) {
    if (isNullish(target)) {
      return Err('$empty received nullish value');
    }

    if (isString(target) || isArray(target)) {
      return Ok(target);
    }

    return Err('$empty can only used for `string` or `array`');
  },
  test(value, expression: EmptyExpression) {
    const { $empty } = expression;

    const isEmpty = value.length === 0;

    return Ok(isEmpty === $empty);
  },
});
