import { pipe } from '@leyan/sand';
import { Ok, map } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

export type NotExpression<TValue> = {
  $not: Expression<TValue>;
};

export const $not = /* @__PURE__ */ operator({
  name: '$not',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(value, expression: NotExpression<any>, path) {
    const { $not } = expression;

    const result = pipe($(value, $not, path))
      .next(($) => map($, (value) => !value))
      .result();

    return result;
  },
});
