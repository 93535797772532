import { isNullish, isString } from '@leyan/sand';
import { Err, Ok } from '@leyan/result';

import { operator } from '../$';

export type CaseSensitiveRegularExpressionExpression = {
  $regex: string;
};

export const $regex = /* @__PURE__ */ operator({
  name: '$regex',
  parse(target) {
    if (isNullish(target)) {
      return Err('$regex received nullish value');
    }

    if (isString(target)) {
      return Ok(target);
    }

    return Err('$regex can only used for `string`');
  },
  test(value, expression: CaseSensitiveRegularExpressionExpression) {
    const { $regex } = expression;

    try {
      return Ok(new RegExp($regex).test(value));
    } catch (_error) {
      return Err('$regex received invalid regular expression');
    }
  },
});
