import { isBoolean } from '@leyan/sand';
import { Err, Ok, isOk } from '@leyan/result';

import type { Expression } from '../types';
import { $, operator } from '../$';

export type CoalesceExpression<TValue> = {
  $coalesce: (boolean | Expression<TValue>)[];
};

export const $coalesce = /* @__PURE__ */ operator({
  name: '$coalesce',
  parse(target) {
    return Ok<unknown>(target);
  },
  test(value, expression: CoalesceExpression<any>, path) {
    const { $coalesce } = expression;

    let result;

    for (const expression of $coalesce) {
      if (isBoolean(expression)) {
        result = Ok(expression);

        return result;
      }

      result = $(value, expression, path);

      if (isOk(result)) {
        return result;
      }
    }

    return result || Err('$coalesce received empty expressions');
  },
});
