import type { Feature, Schema } from './types';

function create<TValue>(value: TValue) {
  return value;
}

/**
 * 定义特性
 *
 * @param config 特性配置
 */
export function defineFeature<TId extends string, TValue>(config: {
  id: TId;
  default: TValue;
  schema?: Schema<TValue>;
}): Feature<{ id: TId; value: TValue }>;

/**
 * 定义特性
 *
 * @param config 特性配置
 */
export function defineFeature<TId extends string, TValue>(config: {
  id: TId;
  schema?: Schema<TValue | undefined>;
}): Feature<{ id: TId; value: TValue | undefined }>;

export function defineFeature<TId extends string, TValue>(config: {
  id: TId;
  default?: TValue;
  schema?: Schema<TValue | undefined>;
}): Feature<{ id: TId; value: TValue | undefined }> {
  return {
    ...config,
    create,
  } as Feature<{ id: TId; value: TValue | undefined }>;
}
