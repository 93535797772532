import type { Logger, LoggerLabels, LoggerMeta } from './types';
import type LoggerManager from './LoggerManager';
import NoopLogger from './NoopLogger';
import { appendParams, combineMessages, formatLabels, formatName } from './utils';

export interface ProxyLoggerOptions {
  manager: LoggerManager;
  name: string;
  labels?: LoggerLabels;
  logger?: Logger;
  parent?: ProxyLogger;
}

class ProxyLogger extends NoopLogger implements Logger {
  readonly manager: LoggerManager;

  readonly name: string;

  private _labels: LoggerLabels;

  private _parent?: ProxyLogger;

  private _logger?: Logger;

  constructor(options: ProxyLoggerOptions) {
    const { name, labels = {}, logger, parent, manager } = options;

    super(({ name = this.name, level = 'info', message = '', params = [], labels }) => {
      const meta: LoggerMeta = {
        name,
        level,
        message,
        params,
        labels: { ...this.getLabels(), ...labels },
      };

      if (this._logger) {
        this.manager.$logging(meta);

        if (this._logger instanceof NoopLogger) {
          this._logger.log(meta);

          return;
        }

        this._logger[level](
          combineMessages(formatName(meta.name), meta.message),
          ...appendParams(meta.params, formatLabels(meta.labels)),
        );

        return;
      }

      const proxyLogger = this._parent || this.manager.getLogger();

      if (!proxyLogger || proxyLogger === this) {
        this.manager.$logging(meta);

        return;
      }

      proxyLogger.log(meta);
    });

    this.name = name;
    this.manager = manager;
    this._labels = { ...labels };
    this._logger = logger;
    this._parent = parent;
  }

  extend(name: string, delimiter = '/') {
    const childName = this.name ? `${this.name}${delimiter}${name}` : name;

    const proxyLogger = this.manager.getLogger(childName);

    proxyLogger.setParent(this);

    return proxyLogger;
  }

  getParent() {
    return this._parent;
  }

  setParent(parent?: ProxyLogger) {
    this._parent = parent;

    return this;
  }

  getLogger() {
    return this._logger;
  }

  setLogger(logger?: Logger) {
    this._logger = logger;

    return this;
  }

  getLabels() {
    return this._labels;
  }

  setLabels(labels: LoggerLabels): this;

  setLabels(updater: (labels: LoggerLabels) => LoggerLabels): this;

  setLabels(maybeLabels: any) {
    const labels = typeof maybeLabels === 'function' ? maybeLabels(this._labels) : maybeLabels;

    this._labels = labels;

    return this;
  }
}

export default ProxyLogger;
