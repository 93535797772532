import { now } from './date';

export function wait(time: number) {
  return new Promise<number>((resolve) => {
    if (time > 0) {
      const start = now(true);

      setTimeout(() => {
        const end = now(true);

        resolve(end - start);
      }, time);
    } else {
      resolve(0);
    }
  });
}
