import { isArray, isNullish } from '@leyan/sand';
import { Err, Ok, unwrap } from '@leyan/result';

import type { Expression, ItemType } from '../types';
import { $, operator } from '../$';

export type SomeExpression<TValue extends readonly any[]> = {
  $some: Expression<ItemType<TValue>>;
};

export const $some = /* @__PURE__ */ operator({
  name: '$some',
  parse(target) {
    if (isNullish(target)) {
      return Err('$some received nullish value');
    }

    if (isArray(target)) {
      return Ok(target);
    }

    return Err('$some can only used for `array`');
  },
  test(value, expression: SomeExpression<any[]>, path) {
    const { $some } = expression;

    let index = 0;

    for (const item of value) {
      const result = $(item, $some, [...path, index]);

      if (unwrap(result, true)) {
        return result;
      }

      index += 1;
    }

    return Ok(false);
  },
});
