import type { Evaluator } from '../types';

function getErrorMessage(reason: unknown) {
  return (reason as Error)?.message || '';
}

/**
 * 创建 `fetch` 特性评估器参数
 */
export interface FetchEvaluatorOptions {
  /**
   * 特性评估服务地址
   */
  endpoint: string;
  /**
   * 执行特性评估超时时间 (毫秒)，默认 `10000`
   */
  timeout?: number;
  /**
   * `fetch` 请求参数
   */
  requestOptions?: Omit<RequestInit, 'method' | 'body' | 'signal'>;
  /**
   * 自定义 `fetch` 实现
   */
  fetch?: typeof fetch;
}

/**
 * 创建 `fetch` 特性评估器
 *
 * @param options 创建 `fetch` 特性评估器参数
 */
export function FetchEvaluator(options: FetchEvaluatorOptions): Evaluator {
  const { endpoint, requestOptions, timeout = 10000, fetch: $fetch = fetch } = options;

  return async (config) => {
    const { appName, appKey, appVersion, deviceId, attributes, features } = config;

    const url = `${endpoint}?appName=${appName}&appKey=${appKey}&appVersion=${appVersion}&deviceId=${deviceId}`;

    let controller: AbortController | undefined;
    let dispose: (() => void) | undefined;
    let response: Response;

    if (timeout > 0) {
      controller = new AbortController();

      const id = setTimeout(() => {
        controller!.abort(new Error(`Evaluate timeout in ${timeout}ms`));
      }, timeout);

      dispose = () => {
        dispose = undefined;

        clearTimeout(id);
      };
    }

    try {
      response = await $fetch(url, {
        ...requestOptions,
        method: 'POST',
        body: JSON.stringify({ attributes, features }),
        signal: controller?.signal,
      });
    } catch (error) {
      throw new Error(`Evaluate failed with fetch error: ${getErrorMessage(error)}`, {
        cause: error,
      });
    } finally {
      dispose?.();
    }

    if (response.status === 204) {
      return undefined;
    }

    if (response.status === 200) {
      try {
        return await response.json();
      } catch (error) {
        throw new Error(`Evaluate failed with parse json error: ${getErrorMessage(error)}`, {
          cause: error,
        });
      }
    }

    throw new Error(`Evaluate failed with status ${response.status}`);
  };
}
