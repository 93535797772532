class SyncContext<TValue> {
  private _value?: TValue;

  run<TResult>(value: TValue, fn: () => TResult) {
    const prev = this._value;

    try {
      this._value = value;

      return fn();
    } finally {
      this._value = prev;
    }
  }

  get() {
    return this._value;
  }
}

export default SyncContext;
