import axios, { type AxiosInstance } from 'axios';
import { now } from '@leyan/sand';

import type { LitoCollector } from '../types';

export function VectorCollector(client: AxiosInstance = axios.create()): LitoCollector {
  return async (options) => {
    const { appname, appkey, version, deviceid, sessionid, events } = options;

    await client.request({
      url: '/events',
      method: 'POST',
      params: {
        appname,
        appkey,
        version,
        deviceid,
        sessionid,
        send_timestamp: now(),
      },
      data: events,
    });
  };
}
