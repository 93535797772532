import { disposable, EMPTY_OBJECT, isFunction, run, strictEquals, SyncContext } from '@leyan/sand';

/**
 * 配置副作用
 */
interface ConfigurationEffect<T> {
  /**
   * 执行副作用
   */
  run(): void;
  /**
   * 清理副作用
   */
  dispose(): void;
  /**
   * 清理函数集合
   */
  disposes: Set<() => void>;
  /**
   * 配置值选择函数集合
   */
  selects: Set<(value: T) => any>;
  /**
   * 子副作用集合
   */
  children: Set<ConfigurationEffect<T>>;
}

/**
 * 配置依赖追踪标识
 */
interface ConfigurationFlags {
  /**
   * 是否追踪依赖
   */
  track?: boolean;
  /**
   * 是否触发更新
   */
  trigger?: boolean;
}

/**
 * 自定义配置值管理工厂函数
 */
export interface ConfigurationFactory<T> {
  ({
    track,
    trigger,
  }: {
    /**
     * 追踪依赖
     */
    track(): void;
    /**
     * 触发更新
     */
    trigger(): void;
  }): {
    /**
     * 获取配置值
     */
    get(): T;
    /**
     * 设置配置值
     *
     * @param value 新配置值
     */
    set(value: T): void;
    /**
     * 激活配置值更新
     */
    activate(): void;
    /**
     * 停止配置值更新
     */
    deactivate(): void;
  };
}

/**
 * 配置参数
 */
export interface ConfigurationOptions {
  /**
   * 配置值变化比较器
   */
  equals?: false | ((a: any, b: any) => boolean);
}

/**
 * 配置
 */
class Configuration<T> {
  /**
   * 配置值变化比较器
   */
  private _equals: false | ((a: any, b: any) => boolean);

  /**
   * 获取配置值
   */
  private _get: () => T;

  /**
   * 设置配置值
   *
   * @param value 新配置值
   */
  private _set: (value: T) => void;

  /**
   * 激活配置值更新
   */
  private _activate: () => void;

  /**
   * 关闭配置值更新
   */
  private _deactivate: () => void;

  /**
   * 配置缓存值
   */
  private _cached?: {
    value: T;
  };

  /**
   * 配置依赖追踪标识上下文
   */
  private _flagsContext: SyncContext<ConfigurationFlags> = new SyncContext();

  /**
   * 配置副作用上下文
   */
  private _effectContext: SyncContext<ConfigurationEffect<T>> = new SyncContext();

  /**
   * 配置副作用集合
   */
  private _effects: Set<ConfigurationEffect<T>> = new Set();

  /**
   * 根据自定义配置值管理工厂函数创建配置
   *
   * @param factory 自定义配置值管理工厂函数
   * @param options 配置参数
   */
  constructor(factory: ConfigurationFactory<T>, options?: ConfigurationOptions);

  /**
   * 根据配置初始值创建配置
   *
   * @param value 配置初始值
   * @param options 配置参数
   */
  constructor(value: T, options?: ConfigurationOptions);

  constructor(
    maybeFactory: T | ConfigurationFactory<T>,
    options: ConfigurationOptions = EMPTY_OBJECT,
  ) {
    const { equals = strictEquals } = options;

    this._equals = equals;

    const { get, set, activate, deactivate } = run(() => {
      const track = () => {
        const flags = this._flagsContext.get();

        if (flags) {
          flags.track = true;

          return;
        }

        this._track();
      };

      const trigger = () => {
        const flags = this._flagsContext.get();

        if (flags) {
          flags.trigger = true;

          return;
        }

        this._trigger();
      };

      if (isFunction(maybeFactory)) {
        return maybeFactory({
          track,
          trigger,
        });
      }

      let _value = maybeFactory;

      return {
        get() {
          track();

          return _value;
        },
        set(value) {
          _value = value;

          trigger();
        },
        activate() {},
        deactivate() {},
      };
    });

    this._get = get;
    this._set = set;
    this._activate = activate;
    this._deactivate = deactivate;
  }

  /**
   * 缓存配置值
   *
   * @param value 配置值
   */
  private _cache(value: T) {
    if (this._cached) {
      this._cached.value = value;

      return;
    }

    this._cached = { value };
  }

  /**
   * 追踪依赖
   *
   * @param select 配置值选择函数
   */
  private _track(select?: (value: T) => any) {
    if (select) {
      const effect = this._effectContext.get();

      if (effect) {
        effect.selects.add(select);
      }
    }
  }

  /**
   * 触发更新
   */
  private _trigger(): void;

  /**
   * 根据新旧配置值触发更新
   *
   * @param prev 旧配置值
   * @param next 新配置值
   */
  private _trigger(prev: T, next: T): void;

  private _trigger(maybePrev?: T, maybeNext?: T) {
    if (this._effects.size === 0) {
      return;
    }

    const result = run(() => {
      if (!this._equals) {
        return false;
      }

      if (arguments.length === 0) {
        if (this._cached) {
          const cached = this._cached.value;

          return () => [cached, this._get()] as const;
        }

        return false;
      }

      return () => [maybePrev!, maybeNext!] as const;
    });

    if (!this._equals || !result) {
      const errors: unknown[] = [];

      for (const effect of this._effects) {
        try {
          effect.run();
        } catch (error) {
          errors.push(error);
        }
      }

      if (errors.length > 0) {
        throw errors[0];
      }

      return;
    }

    const [prev, next] = result();

    if (this._equals(prev, next)) {
      return;
    }

    const dirtyCache: Map<(value: T) => any, boolean> = new Map();
    const errors: unknown[] = [];

    for (const effect of this._effects) {
      try {
        let dirty = false;

        if (effect.selects.size > 0) {
          for (const select of effect.selects) {
            let cachedDirty = dirtyCache.get(select);

            if (cachedDirty === undefined) {
              cachedDirty = !this._equals(select(prev), select(next));

              dirtyCache.set(select, cachedDirty);
            }

            if (cachedDirty) {
              dirty = true;

              break;
            }
          }
        } else {
          dirty = true;
        }

        if (dirty) {
          effect.run();
        }
      } catch (error) {
        errors.push(error);
      }
    }

    if (errors.length > 0) {
      throw errors[0];
    }
  }

  /**
   * 获取配置值
   *
   * @param track 是否追踪依赖，默认追踪依赖
   */
  get(track?: boolean): T;

  /**
   * 选择配置值
   *
   * @param select 配置值选择器
   * @param track 是否追踪依赖，默认追踪依赖
   */
  get<R>(select: (value: T) => R, track?: boolean): R;

  get<R>(maybeSelect?: boolean | ((value: T) => R), maybeTrack?: boolean): T | R {
    const [select, track = true] = isFunction(maybeSelect)
      ? [maybeSelect, maybeTrack]
      : [undefined, maybeSelect];
    const flags: ConfigurationFlags = {};

    return this._flagsContext.run(flags, () => {
      const value = this._get();

      this._cache(value);

      if (track && flags.track) {
        this._track(select);
      }

      if (select) {
        return select(value);
      }

      return value;
    });
  }

  /**
   * 设置配置值
   *
   * @param value 新配置值
   * @param trigger 是否触发更新，默认触发更新
   */
  set(value: T, trigger?: boolean): void;

  /**
   * 更新配置值
   *
   * @param update 配置值更新函数
   * @param trigger 是否触发更新，默认触发更新
   */
  set(update: (value: T) => T, trigger?: boolean): void;

  set(maybeUpdate: T | ((value: T) => T), trigger: boolean = true): void {
    const flags: ConfigurationFlags = {};

    return this._flagsContext.run(flags, () => {
      const prev = this._get();
      const next = isFunction(maybeUpdate) ? maybeUpdate(prev) : maybeUpdate;

      this._set(next);
      this._cache(next);

      if (trigger && flags.trigger) {
        this._trigger(prev, next);
      }
    });
  }

  /**
   * 执行副作用并自动追踪依赖
   *
   * @param run 副作用函数
   */
  effect(run: (onDispose: (dispose: () => void) => void) => void) {
    const effect: ConfigurationEffect<T> = {
      run: () => {
        this._effectContext.run(effect, () => {
          effect.dispose();

          run((dispose) => {
            // 同步注册清理函数加入清理函数集合
            if (this._effectContext.get() === effect) {
              effect.disposes.add(dispose);

              return;
            }

            // 异步注册清理函数直接执行
            dispose();
          });
        });
      },
      dispose: () => {
        this._effectContext.run(effect, () => {
          const errors: unknown[] = [];

          const disposeChildren = () => {
            if (effect.children.size > 0) {
              for (const child of effect.children) {
                try {
                  child.dispose();
                } catch (error) {
                  errors.push(error);
                }

                this._effects.delete(child);
              }

              effect.children.clear();
            }
          };

          // 清理执行副作用添加的子副作用
          disposeChildren();

          for (const dispose of effect.disposes) {
            try {
              dispose();
            } catch (error) {
              errors.push(error);
            }
          }

          // 清理清理副作用添加的子副作用
          disposeChildren();

          effect.disposes.clear();
          effect.selects.clear();

          if (errors.length > 0) {
            throw errors[0];
          }
        });
      },
      disposes: new Set(),
      selects: new Set(),
      children: new Set(),
    };

    const parent = this._effectContext.get();

    if (parent && parent !== effect) {
      parent.children.add(effect);
    }

    const isEmpty = this._effects.size === 0;

    effect.run();

    this._effects.add(effect);

    if (isEmpty && this._effects.size === 1) {
      this._activate();
    }

    return disposable(() => {
      try {
        effect.dispose();
      } finally {
        this._effects.delete(effect);

        if (this._effects.size === 0) {
          this._deactivate();
        }
      }
    });
  }

  /**
   * 清理配置副作用
   */
  dispose() {
    const errors: unknown[] = [];

    for (const effect of this._effects) {
      try {
        effect.dispose();
      } catch (error) {
        errors.push(error);
      }
    }

    this._effects.clear();
    this._deactivate();

    if (errors.length > 0) {
      throw errors[0];
    }
  }
}

export default Configuration;
